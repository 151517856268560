jQuery(function () {
	jQuery('.start-video').click(function () {
		var url = jQuery(this).attr('data-video-url');
		jQuery.fancybox({
			beforeLoad: function () {
				this.title = '<a class="fancybox-title" onclick="parent.jQuery.fancybox.close();" title="Close (Esc)">×</a>'
			},
			helpers: {
				title: {
					type: 'outside',
					position: 'top'
				}
			},
			width: "100%",
			maxWidth: 960,
			autoSize: false,
			scrollOutside: true,
			closeBtn: false,
			content: '<div id="video_container">Loading the player ... </div>',
			afterShow: function () {
				jwplayer("video_container").setup({
					file: url,
					autostart: true,
					width: '100%',
					aspectratio: '16:9',
				});
			}
		})
	});
});
